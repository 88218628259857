import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const Image = styled(Plaatjie)`
  border-radius: 15px;
  overflow: hidden;
  transform: translateZ(0);

  @media (min-width: 992px) {
    height: 250px;
    width: 250px;
  }

  @media (max-width: 991px) {
    height: 200px;
    width: 200px;
  }

  @media (max-width: 575px) {
    width: 125px;
    height: 125px;
  }
`

interface CtaProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Cta
}

const Cta: React.FC<CtaProps> = ({ fields }) => (
  <section>
    <div className="container py-5 my-lg-5">
      <div className="row">
        <div className="col-5 col-sm-6 d-flex justify-content-end pe-md-5">
          <Image image={fields.image} className="mr-lg-4" alt="VT Tuinen" />
        </div>
        <div className="col-7 col-sm-6 d-flex align-items-center">
          <ParseContent content={fields.description} />
        </div>
      </div>
    </div>
  </section>
)

export default Cta
